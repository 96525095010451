import { createUseStyles } from 'react-jss'

import { desktop, fonts, fullDesktop, hdmi } from '../../theme'


const stylesTitle = {
  margin: 0,
  fontSize: '1.6rem',
  textTransform: 'uppercase',
  ...fonts.UniversBoldCondensed,
  'html[lang=kr] &': {
    ...fonts.YDGothicBold,
  },
  'html[lang=jp] &': {
    ...fonts.TazuganeGothicBold,
  },
  'html[lang=cn] &': {
    ...fonts.MYingHeiBold,
  },
}

export default createUseStyles({
  container: {
    alignSelf: 'flex-start',
    height: '100%',
    overflow: 'hidden auto',
  },
  wrapper: {
    paddingLeft: '1.6rem',
    paddingRight: '1.6rem',
    '&::before, &::after': {
      content: '""',
      position: 'absolute',
      left: 0,
      right: 0,
      height: '7.4rem',
      pointerEvents: 'none',
    },
    '&::before': {
      top: 0,
      background: 'linear-gradient(180deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 100%)',
    },
    '&::after': {
      bottom: 0,
      background: 'linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,1) 100%)',
    },
    '& > *': {
      margin: 0,
    },
    '& > * + *': {
      marginTop: '1rem',
    },
  },
  content: {
    maxWidth: '40rem',
    marginLeft: 'auto',
    marginRight: 'auto',
    paddingTop: '7rem',
    paddingBottom: '8rem',
    '& > *': {
      margin: 0,
    },
    '& > *:not(.is-notstaggered)': {
      opacity: 0, // to prevent FOUC
    },
    '& > * + *': {
      marginTop: '1.5rem',
    },
    ...desktop({
      paddingTop: '17rem',
    }),
    ...fullDesktop({
      maxWidth: '40vw',
    }),
    ...hdmi({
      maxWidth: 'min(80rem, 40vw)',
      fallbacks: {
        maxWidth: '80rem',
      },
    }),
  },
  title: {
    ...stylesTitle,
  },
})
