import * as PropTypes from 'prop-types'
import React, { useCallback, useMemo } from 'react'
import { graphql } from 'gatsby'
import { navigate } from 'gatsby-plugin-intl'

import { useIntl } from '../../intl/intl'
import Layout from '../../components/Layout'
import PageTemplate from '../../templates/Page'
import { withMemo, withWebsiteOpen } from '../../decorators'
import { useBlocks } from '../../hooks/useAppLayout'
import { blocksTransformer } from '../../utils/BlockUtils'
import Header from '../../components/Header'
import Icon from '../../components/Icon'
import { iconsKeys } from '../../components/Icon/Icon.assets'
import router from '../../router'
import routes from '../../router/routes'
import * as appConfiguration from '../../configuration'


const Page = (props) => {
  const t = useIntl()
  const { data, pageContext, location } = props
  const blocks = blocksTransformer(data.contentfulPage.content)
  const renderBlocks = useBlocks(blocks, {})
  const pageTemplateProps = useMemo(() => ({
    title: data?.contentfulPage.title ?? '',
    layoutProps: {
      seo: {
        lang: pageContext?.language ?? null,
        title: data?.contentfulPage?.seo?.title ?? '',
        description: data?.contentfulPage?.seo?.description?.description ?? '',
        image: appConfiguration.app.url + data?.contentfulPage?.seo?.picture?.localFile?.childImageSharp?.fluid?.src ?? '',
        url: location?.href ?? '',
      },
    },
    headerProps: {
      isFallHidden: true,
      linkLogoProps: {
        link: t('balenciaga_logo_link'),
        target: '_blank',
      },
    },
  }), [t, data.contentfulPage.seo.description.description, data.contentfulPage.seo.picture.localFile.childImageSharp.fluid.src, data.contentfulPage.seo.title, data.contentfulPage.title, location.href, pageContext.language])

  const onClose = useCallback(() => {
    navigate(router(pageContext?.isVip ? routes.vr.path : routes.home.path))
  }, [pageContext.isVip])

  return (
    <Layout
      header={(
        <Header
          navigation={(
            <>
              <Icon
                icon={iconsKeys.ToCarousel}
                aria-hidden="true"
                onClick={() => {
                  navigate(router(pageContext?.isVip ? routes.vipGridDefault.path : routes.gridDefault.path))
                }}
              />
              <Icon
                icon={iconsKeys.ToGrid}
                aria-hidden="true"
                onClick={() => {
                  navigate(router(pageContext?.isVip ? routes.vipLooks.path : routes.looks.path))
                }}
              />
              <Icon
                icon={iconsKeys.Close}
                aria-hidden="true"
                onClick={onClose}
              />
            </>
          )}
          {...pageTemplateProps.headerProps}
        />
      )}
      {...pageTemplateProps.layoutProps}
    >
      <PageTemplate title={pageTemplateProps.title}>
        {renderBlocks}
      </PageTemplate>
    </Layout>
  )
}

Page.propTypes = {
  // eslint-disable-next-line
  location: PropTypes.object,
  // eslint-disable-next-line
  data: PropTypes.object,
  // eslint-disable-next-line
  pageContext: PropTypes.object,
}

Page.defaultProps = {
  location: null,
  data: null,
  pageContext: null,
}

export default withWebsiteOpen(withMemo()(Page))

export const query = graphql`
   query page($language: String, $slug: String) {
    contentfulPage(node_locale: { eq: $language }, slug: { eq: $slug }) {
      ...balenciagaF21Page
    }
  }
`
