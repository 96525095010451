/* eslint-disable react/forbid-prop-types */
import cx from 'classnames'
import PropTypes from 'prop-types'
import React, { useEffect, useRef } from 'react'
import { gsap } from 'gsap'

import staggerAnimation from '../../utils/staggerAnimation'
import withMemo from '../../decorators/withMemo'

import useStyles from './styles'


const PressTemplate = (props) => {
  const classes = useStyles(props)
  const { className, children, title, TitleTag } = props

  // animations
  const $animatedWrapper = useRef()

  useEffect(() => {
    const timeline = gsap.timeline()

    if ($animatedWrapper.current) {
      const animatedChildren = Array.from($animatedWrapper.current.children).filter((child) => !child.classList.contains('is-notstaggered'))

      staggerAnimation(0, animatedChildren, timeline, 0.5, '+=0.2', 0.5)
      timeline.play()
    }

    // cleanup
    return () => {
      timeline.clear()
    }
  }, [])

  return (
    <main className={cx(className, classes.container)}>
      <div className={classes.wrapper}>
        <div
          className={classes.content}
          ref={$animatedWrapper}
        >
          {title && (
            <TitleTag className={classes.title}>{title}</TitleTag>
          )}
          {children}
        </div>
      </div>
    </main>
  )
}

export const PressTemplatePropTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  title: PropTypes.string,
  TitleTag: PropTypes.string,
}

PressTemplate.propTypes = PressTemplatePropTypes

PressTemplate.defaultProps = {
  className: null,
  children: null,
  title: '',
  TitleTag: 'h1',
}

export default withMemo()(PressTemplate)
