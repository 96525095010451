import { createUseStyles } from 'react-jss'

import { fonts } from '../../../theme'


const stylesTitle = {
  ...fonts.UniversBoldCondensed,
  margin: 0,
  fontSize: '1.6rem',
  textTransform: 'uppercase',
  'html[lang=kr] &': {
    ...fonts.YDGothicBold,
  },
  'html[lang=jp] &': {
    ...fonts.TazuganeGothicBold,
  },
  'html[lang=cn] &': {
    ...fonts.MYingHeiBold,
  },
}

export default createUseStyles({
  text: {
    fontSize: '1.6rem',
    lineHeight: 1.5,
    '& > *': {
      margin: 0,
    },
    '& > * + *': {
      marginTop: '1.5rem',
    },
    '& h1, & h2, & h3, & h4, & h5': {
      ...stylesTitle,
    },
  },
})
