import * as transformers from '../blocks/transformers'


export function blocksTransformer(blocks, utils) {
  if (!blocks) {
    return null
  }

  const missingBlocks = blocks
    .filter((props) => !transformers[props.__typename])
    .map((props) => props.__typename)

  if (missingBlocks.length) {
    console.log('[Page] Missing transformer :', missingBlocks.join(', '))
  }

  const transformed = []
  const filtered = blocks.filter((props) => transformers[props.__typename])

  for (let i = 0; i < filtered.length; i++) {
    const props = filtered[i]
    const rs = transformers[props.__typename](props, utils)

    if (rs.blocks) {
      rs.blocks = blocksTransformer(rs?.blocks, utils)
    }

    transformed.push(rs)
  }

  return transformed
}
