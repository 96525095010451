
import React, { useCallback, useMemo } from 'react'

import * as components from '../blocks'


export const useBlocks = (blocks, extraParams = {}) => {
  const renderBlock = useCallback((block, key) => {
    const Block = components[block.__typename]

    if (!Block) {
      return null
    }

    return (
      <Block
        {...block}
        {...extraParams}
        key={`${block.__typename}-${key}`}
      />
    )
  }, [extraParams])

  const renderBlocks = useMemo(() => blocks?.map(renderBlock), [blocks, renderBlock])

  return [renderBlocks]
}
