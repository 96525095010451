
import React from 'react'
import PropTypes from 'prop-types'

import RichText from '../../RichText'

import useStyles from './styles'


const ContentfulBlockHtml = (props) => {
  const { text } = props
  const classes = useStyles(props)

  return (
    <RichText
      className={classes.text}
      json={text}
    />
  )
}

ContentfulBlockHtml.propTypes = {
  text: PropTypes.string,
}

ContentfulBlockHtml.defaultProps = {
  text: '',
}

export default ContentfulBlockHtml
